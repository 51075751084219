<template>
  <BaseCard
    class="h-max mt-5 w-4/5 pt-5 flex-col justify-between items-center"
    :no-padding="true"
  >
    <div
      class="flex w-full items-center justify-between px-5 border-b border-border-normal pb-5"
    >
      <div class="flex items-center">
        <img
          src="../../assets/icons/discovery.png"
          class="w-10 h-10"
        >
        <div class="ml-3">
          <BaseText
            type="label"
            size="md"
            class="text-neutral-900 font-medium"
          >
            Discovery
          </BaseText>

          <BaseText
            type="body"
            size="sm"
            class="text-text-normal w-52 overflow-visible whitespace-nowrap"
          >
            {{ dailyAdsSavedCount?.count }} New Ads Today!
          </BaseText>
        </div>
      </div>
      <router-link
        :to="{
          name: 'DiscoveryView',
        }"
      >
        <BaseButton
          type="primary"
          size="sm"
          outlined
          class="w-max flex justify-between items-center gap-2 flex-nowrap ml-auto shadow-sm"
        >
          <div class="flex gap-1 items-center -ml-2">
            <img
              src="../../assets/icons/search.svg"
              alt=""
            >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted pl-0.5"
            >
              Browse Disovery
            </BaseText>
          </div>
        </BaseButton>
      </router-link>
    </div>
    <div class="grid grid-cols-2">
      <BaseText
        type="body"
        size="sm"
        class="text-text-disabled pl-4 ml-0.5 border-r border-border-normal pt-3"
      >
        <div class="flex items-center gap-1">
          Trending Ads Today
          <BaseTooltip dark>
            <img
              slot="reference"
              class="cursor-pointer"
              src="../../assets/icons/questionmark.svg"
            >
            <div
              slot="content"
              class="px-2 w-36"
            >
              Most popular ads over last 7 Days
            </div>
          </BaseTooltip>
          <BaseSelect
            v-click-outside="
              () => {
                closeDropdown();
              }
            "
            class="ml-auto"
            no-shadow
            no-padding
            white-background
            scrollable
            :close-dropdown="leaveDropdown"
            item-value="name"
            :items="languages"
            @click.stop.native="() => {}"
          >
            <div
              slot="reference"
              class="flex items-center justify-center object-fit opacity-60 hover:opacity-90 text-white transition duration-300 select-none cursor-pointer"
              @click="closeDropdown()"
            >
              <BaseText
                type="body"
                size="sm"
                class="text-text-muted w-max"
              >
                {{ language }}
              </BaseText>
              <img
                class="ml-1"
                src="../../assets/icons/chevron-down-small.svg"
              >
            </div>
            <template #item="{ item }">
              <div
                class="flex items-center w-full h-full cursor-pointer"
                @click="
                  () => {
                    language = item;
                  }
                "
              >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-muted w-max"
                >
                  {{ item }}
                </BaseText>
              </div>
            </template>
          </BaseSelect>
        </div>
      </BaseText>
      <BaseText
        type="body"
        size="sm"
        class="text-text-disabled pl-4 ml-0.5 px-2.5 flex items-center pt-3"
      >
        <div class="flex items-center gap-1">
          Favourite Brands {{ !loadingBrands ? `(${brands?.length})` : "" }}
          <BaseTooltip dark>
            <img
              slot="reference"
              class="cursor-pointer"
              src="../../assets/icons/questionmark.svg"
            >
            <div
              slot="content"
              class="px-2 w-36"
            >
              Favourite brands from Discovery
            </div>
          </BaseTooltip>
        </div>
      </BaseText>
    </div>
    <div
      class="grid grid-cols-2 grid-rows-1"
      style="max-height: 550px"
    >
      <div
        class="flex-col h-max py-5 items-center justify-start border-r border-border-normal col-span-1 row-span-1 transition-all duration-500 ease-in-out"
      >
        <div
          class="flex items-center justify-between w-full h-full transition-all duration-300 ease-in-out"
        >
          <div
            v-if="
              trendingAds?.length > 0 &&
              trendingAds[currentIndex] &&
              !loadingTrendingAds
            "
            class="w-full flex items-center justify-center"
          >
            <!-- Left Arrow -->
            <div
              class="p-2 select-none rounded-full cursor-pointer mx-2.5 border border-border-normal shadow-sm hover:bg-background-hover transition-colors duration-200"
              @click="nextAd"
            >
              <img
                class="transform -rotate-90 mr-px"
                src="../../assets/icons/chevron-top-small.svg"
              >
            </div>

            <!-- Content -->
            <div
              v-if="trendingAds?.length && trendingAds[currentIndex]"
            >
              <div
                v-for="(ad, index) in trendingAds"
                :key="index"
                class="flex flex-col justify-center items-center py-1 w-80 px-2"
              >
                <LibraryCard
                  v-if="trendingAds?.length && trendingAds[currentIndex] && trendingAds[currentIndex] === ad"
                  class="bg-background-normal rounded-lg"
                  :advertisement="ad"
                  disable-ad-settings
                  shrink-media
                  small-ads
                  shared-board
                  discovery
                  @openDetails="openDetailDrawer = true"
                />
              </div>
            </div>
            <!-- Right Arrow -->
            <div
              class="p-2 mx-2.5 select-none rounded-full cursor-pointer border border-border-normal shadow-sm hover:bg-background-hover transition-colors duration-200"
              @click="previousAd"
            >
              <img
                class="transform rotate-90 ml-px"
                src="../../assets/icons/chevron-top-small.svg"
              >
            </div>
          </div>
          <div
            v-else-if="!loadingTrendingAds"
            class="text-center rounded-lg flex-shrink-0 h-full w-full m-auto flex items-center justify-center"
          >
            <BaseText
              type="body"
              size="sm"
              class="w-max text-blue-500"
            >
              No Ads
            </BaseText>
          </div>
          <div
            v-else
            class="m-auto"
          >
            <BaseLoadingLogo />
          </div>
        </div>
      </div>
      <div
        v-if="brands?.length && !loadingBrands"
        class="flex flex-col items-center overflow-y-scroll scrollbar-hide px-3 h-full"
        style="max-height: 550px;"
      >
        <div
          v-for="(brand, index) in brands"
          :key="brand?.id"
          class="flex items-center group w-full hover:bg-background-normal gap-2 mt-2 cursor-pointer p-2 rounded-md transition-colors duration-200 group"
        >
          <router-link
            :to="{ name: 'DiscoveryBrandView', params: { id: brand?.id } }"
            class="flex flex-1 items-center gap-2 flex-nowrap"
          >
            <img
              :src="brand?.avatar"
              alt=""
              class="w-5 h-5 rounded-full"
            >
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted pl-0.5"
            >
              {{ brand?.name }}
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-disabled pl-0.5"
            >
              {{ lastVisited[index] ? lastVisited[index] + ' new' : '' }}
            </BaseText>
          </router-link>
          <div
            class="ml-auto transition-all duration-200 h-6 w-6 hover:text-icon-disabled relative flex items-center justify-center ease-in-out opacity-0 group-hover:opacity-100"
            @click="() => favoriteBrand(brand?.id)"
          >
            <svg
              class="opacity-100 transition-all duration-200 text-secondary-red-100 cursor-pointer hover:text-text-loud filter"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M10.5 5.06283C15.7776 -0.303486 24.8248 9.66255 10.5 17.7112C-3.82486 9.66258 5.22238 -0.303488 10.5 5.06283Z"
                fill="#FDD9E0"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              class="opacity-0 hover:opacity-100 transition-all duration-200 text-icon-loud cursor-pointer hover:text-text-normal absolute"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M10.5 5.06283C15.7776 -0.303486 24.8248 9.66255 10.5 17.7112C-3.82486 9.66258 5.22238 -0.303488 10.5 5.06283Z"
                fill="#ECEFF3"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        v-else-if="!loadingBrands && !brands?.length"
        class="flex w-full h-full flex-col items-center p-4 pt-0"
      >
        <img
          src="../../assets/images/favourite-brands-image.png"
          alt=""
          class="w-4/5 mt-6 -mb-4"
        >
        <BaseText
          type="label"
          size="md"
          class="text-text-muted"
        >
          <div class="flex items-center gap-1">
            Favourite Brands
          </div>
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal"
        >
          Quick link to the brands who inspire you
        </BaseText>
        <div
          class="flex items-center justify-center cursor-pointer w-max mx-auto mt-auto mb-5"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-primary-blue-100"
          >
            Show Me How
          </BaseText>
          <svg
            class="transform rotate-90 ml-0.5 text-primary-blue-100"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66675 11.6669L9.41083 8.92279C9.73626 8.59735 10.2639 8.59735 10.5893 8.92279L13.3334 11.6669"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div
        v-else
        class="m-auto"
      >
        <BaseLoadingLogo />
      </div>
    </div>
    <div class="flex items-start">
      <div
        class="w-full h-16 flex justify-center items-center border-t border-border-normal py-2"
      >
        <router-link
          class="flex justify-between items-center"
          :to="{
            name: 'DiscoveryView',
          }"
        >
          <BaseButton
            type="primary"
            size="sm"
            class="w-max flex justify-between items-center gap-2 flex-nowrap cursor-pointer p-2 rounded-md transition-colors duration-200"
            @click="showAdvertisementModal = true"
          >
            <div class="flex items-center">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted font-medium"
              >
                View All
              </BaseText>
              <img
                src="../../assets/icons/chevron-top-small.svg"
                alt=""
                class="transform rotate-90"
              >
            </div>
          </BaseButton>
        </router-link>
      </div>
      <div class="flex w-0.5 h-16 bg-border-normal" />
      <div
        class="w-full h-16 flex justify-center items-center border-t border-border-normal py-2"
      >
        <router-link
          class="flex justify-between items-center"
          :to="{
            name: 'DiscoveryBrandsView',
          }"
        >
          <BaseButton
            type="primary"
            size="sm"
            class="w-max flex justify-between items-center gap-2 flex-nowrap cursor-pointer p-2 rounded-md transition-colors duration-200"
          >
            <div class="flex items-center">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted font-medium"
              >
                View All
              </BaseText>
              <img
                src="../../assets/icons/chevron-top-small.svg"
                alt=""
                class="transform rotate-90"
              >
            </div>
          </BaseButton>
        </router-link>
      </div>
    </div>
    <AdvertisementDetailsDrawer
      v-if="openDetailDrawer && trendingAds?.length && currentAd"
      discovery
      :advertisement="currentAd"
      @close="openDetailDrawer = false"
      @save="$emit('reload')"
    />
  </BaseCard>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import ClickOutside from 'vue-click-outside'
import LibraryCard from '../library/LibraryCard.vue'
import AdvertisementDetailsDrawer from '../advertisements/AdvertisementDetailsDrawer.vue'

export default {
  name: 'DiscoveryCard',
  directives: {
    ClickOutside
  },
  components: {
    LibraryCard,
    AdvertisementDetailsDrawer
  },
  data () {
    return {
      favoritedBrands: [],
      loadingBrands: true,
      loadingTrendingAds: true,
      brands: [],
      brandTotal: '0',
      sortOrder: [{ name: 'A-Z', value: 'asc' }],
      favoriteFilter: true,
      nextIndex: 3,
      currentIndex: 1,
      previousIndex: 0,
      language: 'English',
      trendingAds: [],
      carouselIndex: 0,
      openDetailDrawer: false,
      languages: [],
      leaveDropdown: false,
      loadedContent: false,
      currentAd: null,
      lastVisited: [],
      dailyAdsSavedCount: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('AdvertisementsModule', ['getAdPlaying'])
  },
  watch: {
    async language () {
      await this.fetchTrendingAds()
      if (this.trendingAds.length) {
        this.currentIndex = 0
        this.currentAd = this.trendingAds[this.currentIndex]
      }
    },
    currentIndex () {
      this.SET_AD_PLAYING(this.trendingAds[this.currentIndex].ad_id)
    }
  },
  async mounted () {
    await this.fetchLanguages()
    await this.fetchTrendingAds()
    if (this.brands.length === 0) {
      this.favoritedBrands = (await this?.getUser?.favoritedBrands) || []
      await this.fetchFavoritedBrands()
      this.currentAd = this.trendingAds[this.currentIndex]
    }
    const adCount = this.brands.map((brand) => brand.adsSaved)
    const counts = await this.fetchLastVisited()
    this.lastVisited = counts.map((count, index) => {
      return count?.currentCount >= adCount[index] || isNaN(adCount[index] - count?.currentCount) ? '' : adCount[index] - count?.currentCount
    })
    await this.fetchDailyDiscovery()
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_AD_PLAYING']),
    ...mapMutations('AuthModule', ['SET_USER']),
    closeDropdown () {
      this.leaveDropdown = true
    },
    nextAd () {
      if (this.currentIndex === this.trendingAds.length - 1) {
        this.currentIndex = 0
      } else {
        this.currentIndex = this.currentIndex + 1
      }
    },
    previousAd () {
      if (this.currentIndex === 0) {
        this.currentIndex = this.trendingAds.length - 1
        this.currentAd = this.trendingAds[this.currentIndex]
      } else {
        this.currentIndex = this.currentIndex - 1
        this.currentAd = this.trendingAds[this.currentIndex]
      }
    },
    async fetchTrendingAds () {
      this.loadingTrendingAds = true
      this.trendingAds = []
      const db = firebase.firestore()

      const dateObj = new Date()
      const month = dateObj.getUTCMonth() + 1 // months from 1-12
      const day = dateObj.getUTCDate()
      const year = dateObj.getUTCFullYear()
      const newDate = year + '_' + month + '_' + day

      const ref = db.collection('trendingAds').doc(newDate)

      const trendingAdsInstance = await ref
        .collection('languages')
        .doc(this.language)
        .get()

      const adIds = trendingAdsInstance.data().trending.map((ad) => ad.key)
      const allAds = await Promise.all(
        adIds.map(async (adId) => {
          const snapshot = await db
            .collection('unique-ads')
            .where('ad_id', '==', Number(adId))
            .get()
          return snapshot.docs[0].data()
        })
      )

      this.trendingAds = allAds
      this.loadingTrendingAds = false
    },
    async fetchLanguages () {
      const db = firebase.firestore()
      const snapshot = await db.collection('languages').get()
      this.languages = snapshot.docs
        .map((doc) => doc.data().language)
        .sort((a, b) => a.localeCompare(b))
    },
    async fetchFavoritedBrands () {
      this.currentIndex = 0
      if (this.favoritedBrands?.length === 0) {
        this.loadingBrands = false
        return
      }
      const db = firebase.firestore()
      if (this.brands.length) {
        this.brands = []
      }

      const favouriteBrandsSnapshot = Promise.all(this.favoritedBrands.map(async brandId => {
        const snapshot = await db
          .collection('brands')
          .doc(brandId)
          .get()
        return { ...snapshot.data(), id: snapshot.id }
      }))

      this.brands = await favouriteBrandsSnapshot

      this.loadingBrands = false
    },
    async favoriteBrand (brandId) {
      const favoritedBrands = this.favoritedBrands.filter(
        (bId) => bId !== brandId
      )
      // Update the firebase user
      const user = firebase.auth().currentUser

      await FirebaseAPI.Users.update(user.uid, {
        favoritedBrands
      })

      // Add to local state
      this.favoritedBrands = favoritedBrands
      this.SET_USER({
        ...this.getUser,
        favoritedBrands
      })
      this.brands = this.brands.filter((brand) => brand.id !== brandId)
    },
    async firebaseQuery () {
      // Setup
      const db = firebase.firestore()

      const createdCondition = this.getTeam
        ? ['teamId', '==', this.getTeam.id]
        : ['createdBy', '==', this.getUser.user_id]

      const query = await db
        .collection('user-brands')
        .where(...createdCondition)
        .orderBy('name', this.sortOrder[0].value)

      return query
    },
    async fetchLastVisited () {
      const db = firebase.firestore()
      const visitedCounts = Promise.all(
        this.favoritedBrands.map(async (brandId) => {
          const visitedBrand = await db
            .collection('brands-visited')
            .doc(`${this.getUser.user_id}-${brandId}`)
            .get()
          return visitedBrand.data()
        })
      )
      return visitedCounts
    },
    async fetchDailyDiscovery () {
      const db = firebase.firestore()
      const dailyAdsCount = await db
        .collection('counters')
        .doc('dailySavedCount')
        .get()
      this.dailyAdsSavedCount = dailyAdsCount.data()
    }
  }
}
</script>
<style scoped></style>
