<template>
  <div class="flex w-full flex-col items-center justify-center">
    <div class="h-max mt-5 w-4/5 flex-col justify-between items-center relative bg-white rounded-3xl shadow h-96 overflow-hidden">
      <img
        src="../../assets/images/barryThumbnail.png"
        alt=""
        class="w-full h-full rounded-2xl object-cover object-center"
      >
      <img
        src="../../assets/images/barryCourseLogo.svg"
        class="absolute top-8 left-8 h-14"
      >
      <div class="absolute bottom-0 bg-gradient-to-t from-black to-transparent w-full h-64 rounded-3xl" />
      <div class="flex items-center absolute bottom-9 left-7">
        <div class="ml-3">
          <BaseText
            type="label"
            size="md"
            class="text-white font-medium"
          >
            Free Lesson: Optimizing for Ads People Love
          </BaseText>

          <BaseText
            type="body"
            size="sm"
            class="text-text-disabled w-36 overflow-visible whitespace-nowrap"
          >
            Get access to a free preview lesson from Barry Hott's new course.
          </BaseText>
        </div>
      </div>
      <BaseButton
        outlined
        class="w-max flex justify-between items-center gap-2 flex-nowrap ml-px absolute bottom-20 right-8"
        @click="showCourseModal = true"
      >
        <div
          class="flex gap-2 items-center bg-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            class="-mx-1"
          >
            <path
              d="M14.4858 9.35487L7.56793 4.90765C6.45874 4.1946 5 4.991 5 6.30961V15.2041C5 16.5227 6.45875 17.3191 7.56793 16.606L14.4858 12.1588C15.5064 11.5027 15.5064 10.0109 14.4858 9.35487Z"
              stroke="#5E6678"
              stroke-width="1.25"
              stroke-linejoin="round"
            />
          </svg>
          Watch Now
        </div>
      </BaseButton>
      <a
        href="https://www.buildingadswithbarry.com/?foreplay200"
        target="_blank"
        class="discount-button flex items-center gap-2 absolute bottom-9 right-8 rounded-lg text-white px-3 py-1.5 transition-all duration-200"
        v-clipboard:copy="'foreplay200'"
        v-clipboard:success="handleClipboardCopy"
      >
        <TagIcon
          stroke="#fff"
          class="w-5 h-5"
        />
        <span>Save $200 With Code "foreplay200"</span>
      </a>
    </div>
    <CourseModal
      v-if="showCourseModal"
      @close="showCourseModal = false"
    />
  </div>
</template>

<script>
import CourseModal from '../../components/dashboard/Modals/CourseModal.vue'
import TagIcon from '../globals/Icons/TagIcon.vue'
export default {
  name: 'CourseCard',
  components: {
    CourseModal,
    TagIcon
  },
  data () {
    return {
      showCourseModal: false
    }
  },
  methods: {
    handleClipboardCopy () {
      this.$showAlert({
        message: 'Discount code copied to clipboard!',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>
.discount-button {
  background: linear-gradient(180deg, rgba(223, 225, 231, 0.00) 0%, rgba(223, 225, 231, 0.08) 100%), rgba(255, 255, 255, 0.15);
}

.discount-button:hover {
  background: linear-gradient(180deg, rgba(223, 225, 231, 0.00) 0%, rgba(223, 225, 231, 0.08) 100%), rgba(255, 255, 255, 0.25);
}
</style>
