<template>
  <BaseCard
    class="h-max mt-5 w-4/5 pt-5 flex-col justify-between items-center"
    :no-padding="true"
  >
    <div class="flex w-full items-center justify-between px-5 border-b border-border-normal pb-5">
      <div class="flex items-center">
        <img
          src="../../assets/icons/briefs.png"
          class="w-10 h-10"
        >
        <div class="ml-3">
          <BaseText
            type="label"
            size="md"
            class="text-neutral-900 font-medium"
          >
            Briefs
          </BaseText>

          <BaseText
            type="body"
            size="sm"
            class="text-text-normal w-52 overflow-visible whitespace-nowrap"
          >
            You have {{ briefs?.length }} active briefs!
          </BaseText>
        </div>
      </div>
      <router-link
        :to="{name: 'BriefsView'}"
      >
        <BaseButton
          type="primary"
          size="sm"
          outlined
          class="w-max flex justify-between items-center gap-2 flex-nowrap ml-auto shadow-sm"
          @click="showAdvertisementModal = true"
        >
          <div
            class="flex gap-1 items-center -ml-2"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.29171 5.62508H12.7084M7.29171 8.95842H12.7084M7.29171 12.2917H9.37504M5.62504 17.7084H14.375C15.2955 17.7084 16.0417 16.9622 16.0417 16.0417V3.95841C16.0417 3.03794 15.2955 2.29175 14.375 2.29175H5.62504C4.70457 2.29175 3.95837 3.03794 3.95837 3.95841V16.0417C3.95837 16.9622 4.70457 17.7084 5.62504 17.7084Z"
                stroke="#5E6678"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <BaseText
              type="label"
              size="sm"
              class="text-text-muted pl-0.5"
            >
              Create Brief
            </BaseText>
          </div>
        </BaseButton>
      </router-link>
    </div>
    <div
      class="flex w-full h-72"
    >
      <div
        class="w-1/2 flex-col p-2  border-r border-border-normal"
      >
        <BaseText
          type="body"
          size="sm"
          class="text-text-disabled ml-2"
        >
          <div
            class="flex items-center gap-1"
          >
            Active Briefs
            <BaseTooltip dark>
              <img
                slot="reference"
                class="cursor-pointer"
                src="../../assets/icons/questionmark.svg"
              >
              <div
                slot="content"
                class="px-2 w-36"
              >
                Most recently modified Briefs
              </div>
            </BaseTooltip>
          </div>
        </BaseText>
        <div
          v-if="briefs?.length > 0"
          class="w-full h-64 flex-col items-center justify-between overflow-y-scroll scrollbar-hide pt-1"
        >
          <div
            v-for="brief in briefs"
            :key="brief.id"
            class="flex items-center w-full h-max hover:bg-background-normal rounded-md cursor-pointer mb-1.5"
          >
            <router-link
              :to="{ name: 'EditBriefView', params: { id: brief.id } }"
              class="flex items-center w-full h-max p-2 hover:bg-background-normal rounded-md cursor-pointer"
            >
              <svg
                class="w-5 h-5 mr-2 text-text-subdued"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7085 3.95835H14.3752C15.2956 3.95835 16.0418 4.70455 16.0418 5.62502V16.0417C16.0418 16.9622 15.2956 17.7084 14.3752 17.7084H5.62516C4.70469 17.7084 3.9585 16.9622 3.9585 16.0417V5.62502C3.9585 4.70455 4.70469 3.95835 5.62516 3.95835H7.29183M12.7085 3.95835V5.20835C12.7085 5.66859 12.3354 6.04169 11.8752 6.04169H8.12516C7.66493 6.04169 7.29183 5.66859 7.29183 5.20835V3.95835M12.7085 3.95835C12.7085 3.03788 11.9623 2.29169 11.0418 2.29169H8.9585C8.03802 2.29169 7.29183 3.03788 7.29183 3.95835"
                  stroke="currentColor"
                  stroke-opacity="1"
                  stroke-width="1.5"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>

              <BaseText
                type="body"
                size="sm"
                class="text-text-muted"
              >
                {{ brief.name }}
              </BaseText>
              <BaseBadge
                type="primary"
                dot
                :gray="!brief.status || brief.status === 'No Status' || brief.status === 'To Do'"
                :blue="brief.status === 'In Progress'"
                :yellow="brief.status === 'Needs Review'"
                :green="brief.status === 'Completed'"
                size="sm"
                class="ml-auto"
              >
                <div>
                  <BaseText
                    type="body"
                    size="xs"
                    class="text-neutral-900"
                  >
                    {{ brief.status || 'No Status' }}
                  </BaseText>
                </div>
              </BaseBadge>
            </router-link>
          </div>
        </div>
        <div
          v-else-if="loading"
          class="w-full h-64 flex-col items-center justify-between"
        >
          <BaseLoadingLogo />
        </div>
        <div
          v-else
          class="w-full h-64 flex-col items-center justify-between overflow-hidden"
        >
          <div
            class="w-full mx-auto flex-col items-around justify-between overflow-y-hidden px-20 h-72 pt-10"
          >
            <div
              class="flex flex-col w-full items-center justify-center -mt-16 h-64"
            >
              <img
                src="../../assets/images/default-briefs.png"
                alt=""
                class="w-full h-full object-contain "
              >
            </div>
            <div
              class="flex flex-col w-full items-center justify-center flex-shrink"
              style="margin-top: -28%;"
            >
              <BaseText
                type="label"
                size="md"
                class="text-text-loud text-center"
              >
                No Briefs Yet
              </BaseText>
              <BaseText
                type="body"
                size="sm"
                class="text-text-normal whitespace-nowrap overflow-visible w-full text-center"
              >
                Recently Modified Briefs will display here.
              </BaseText>
              <a
                href="https://www.youtube.com/watch?v=gUAspEVLZ4M&ab_channel=foreplay"
                target="_blank"
                class="w-max h-max cursor-pointer"
              >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-blue-500 text-center cursor-pointer h-32"
                >
                  <div
                    class="flex items-center m-auto h-1/3"
                  >
                    Learn About Briefs
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="transform -rotate-90 text-blue-500"
                    >
                      <path
                        d="M6.6665 8.33333L9.41058 11.0774C9.73602 11.4028 10.2637 11.4028 10.5891 11.0774L13.3332 8.33333"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </BaseText>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-1/2 flex-col p-2 relative"
      >
        <BaseText
          type="body"
          size="sm"
          class="text-text-disabled ml-2"
        >
          <div
            class="flex items-center gap-1"
          >
            New Submissions
            <BaseTooltip dark>
              <img
                slot="reference"
                class="cursor-pointer"
                src="../../assets/icons/questionmark.svg"
              >
              <div
                slot="content"
                class="px-2 w-36"
              >
                Most recent asset submissions
              </div>
            </BaseTooltip>
          </div>
        </BaseText>
        <div
          v-if="briefs?.length > 0 && submissions?.length > 0"
          class="w-full flex-col items-center justify-between h-64 overflow-y-scroll scrollbar-hide pt-1"
        >
          <div
            v-for="submission in submissions"
            :key="submission?.submission?.submittedAt "
            class="flex items-center w-full h-max hover:bg-background-normal rounded-md cursor-pointer mb-1.5 group py-2"
          >
            <!-- <div
              class="absolute mb-11 pl-28"
            >
              <div
                class=" w-max py-1 px-2 bg-neutral-700 right-1/2 rounded flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-200 delay-200 ease-in ml-4"
              >
                <svg
                  class="w-4 h-4 mr-1 text-icon-disabled"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7085 3.95835H14.3752C15.2956 3.95835 16.0418 4.70455 16.0418 5.62502V16.0417C16.0418 16.9622 15.2956 17.7084 14.3752 17.7084H5.62516C4.70469 17.7084 3.9585 16.9622 3.9585 16.0417V5.62502C3.9585 4.70455 4.70469 3.95835 5.62516 3.95835H7.29183M12.7085 3.95835V5.20835C12.7085 5.66859 12.3354 6.04169 11.8752 6.04169H8.12516C7.66493 6.04169 7.29183 5.66859 7.29183 5.20835V3.95835M12.7085 3.95835C12.7085 3.03788 11.9623 2.29169 11.0418 2.29169H8.9585C8.03802 2.29169 7.29183 3.03788 7.29183 3.95835"
                    stroke="currentColor"
                    stroke-opacity="1"
                    stroke-width="1.5"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
                <BaseText
                  type="body"
                  size="xs"
                  class="text-white mr-0.5"
                >
                  {{ submission.brief.name }}
                </BaseText>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute -bottom-1.5 mb-px ml-1"
                  width="12"
                  height="6"
                  viewBox="0 0 12 6"
                  fill="none"
                >
                  <path
                    id="Polygon 2"
                    d="M6 0.78125H12L7.41421 5.36704C6.63317 6.14808 5.36684 6.14809 4.58579 5.36704L0 0.78125H6Z"
                    fill="#212431"
                  />
                </svg>
              </div>
            </div> -->
            <router-link
              :to="{ name: 'EditBriefView', params: { id: submission?.brief?.id } }"
              class="flex items-center w-full h-max rounded-md cursor-pointer p-2 py-0"
            >
              <div class="h-5 w-5 object-fit box-border mr-2 bg-bg-normal overflow-hidden flex justify-center flex-shrink bg-background-pressed rounded-sm border border-border-normal transition-transform duration-300 delay-75 transform hover:scale-150 ease-out ">
                <a
                  :href="submission?.submission?.path"
                  target="_blank"
                  download
                  @click.stop=""
                >
                  <img
                    v-if="submission?.submission?.format === 'image/jpg' || submission?.submission?.format === 'image/jpeg' || submission?.submission?.format === 'image/png' || submission?.submission?.format === 'image/gif' || submission?.submission?.format === 'image/bmp' || submission?.submission?.format === 'image/webp' || submission?.submission?.format === 'image/svg+xml'"
                    :src="submission?.submission?.path"
                  >
                  <video
                    v-if="submission?.submission?.format === 'video/mp4'"
                    :src="submission?.submission?.path"
                  />
                </a>
              </div>
              <BaseText
                type="body"
                size="sm"
                class="text-text-muted w-2/5 truncate whitespace-nowrap"
              >
                {{ submission?.submission.name?.substring(0, (submission?.submission.name?.length - getFormat(submission?.submission.format).length)) }}
                <span
                  class="-ml-1 text-text-subdued"
                >
                  {{ getFormat(submission?.submission?.format) }}
                </span>
              </BaseText>
              <BaseText
                v-if="submission?.submission?.submittedAt"
                type="body"
                size="sm"
                class="text-text-muted w-max ml-auto truncate whitespace-nowrap"
              >
                {{ formatDate(submission?.submission?.submittedAt) }}
              </BaseText>
            </router-link>
          </div>
        </div>
        <div
          v-else-if="loading"
          class="w-full h-64 flex-col items-center justify-between overflow-y-scroll scrollbar-hide"
        >
          <BaseLoadingLogo />
        </div>
        <div v-else>
          <div
            class="w-full mx-auto flex-col items-around justify-between overflow-y-hidden scrollbar-hide px-20 h-72 pt-10"
          >
            <div
              class="flex flex-col w-full items-center justify-center -mt-16 h-64"
            >
              <img
                src="../../assets/images/default-submissions.png"
                alt=""
                class="w-full h-full object-contain "
              >
            </div>
            <div
              class="flex flex-col w-full items-center justify-center flex-shrink"
              style="margin-top: -28%;"
            >
              <BaseText
                type="label"
                size="md"
                class="text-text-loud text-center"
              >
                No Submissions Yet
              </BaseText>
              <BaseText
                type="body"
                size="sm"
                class="text-text-normal whitespace-nowrap overflow-visible w-full text-center"
              >
                Recent creator submissions will display here.
              </BaseText>
              <a
                href="https://intercom.help/foreplay/en/articles/8202671-brief-submissions"
                target="_blank"
                class="w-max h-max cursor-pointer"
              >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-blue-500 text-center h-32"
                >
                  <div
                    class="flex items-center m-auto h-1/3"
                  >
                    Learn More
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="transform -rotate-90 text-blue-500"
                    >
                      <path
                        d="M6.6665 8.33333L9.41058 11.0774C9.73602 11.4028 10.2637 11.4028 10.5891 11.0774L13.3332 8.33333"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </BaseText>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex w-full"
    >
      <div
        class="w-1/2 h-16 flex items-center justify-center py-2 border-t border-border-normal"
      >
        <router-link
          :to="{name: 'BriefsView'}"
        >
          <BaseButton
            type="primary"
            size="md"
            class="w-max"
          >
            <div class="flex items-center">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                View All
              </BaseText>
              <img
                src="../../assets/icons/chevron-top-small.svg"
                alt=""
                class="transform rotate-90"
              >
            </div>
          </BaseButton>
        </router-link>
      </div>
      <div
        class="w-px flex items-center justify-center bg-border-normal"
      />
      <div
        class="w-1/2 h-16 items-center justify-center flex py-2 border-t border-border-normal"
      >
        <router-link
          :to="{name: 'BriefsView'}"
        >
          <BaseButton
            type="primary"
            size="md"
            class="w-max"
          >
            <div class="flex items-center">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted "
              >
                View All
              </BaseText>
              <img
                src="../../assets/icons/chevron-top-small.svg"
                alt=""
                class="transform rotate-90"
              >
            </div>
          </BaseButton>
        </router-link>
      </div>
    </div>
  </basecard>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BriefsCard',
  data () {
    return {
      briefs: [],
      submissions: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getBriefs'])
  },
  async mounted () {
    await this.fetchBriefs()
    this.briefs = this?.getBriefs
    this.submissions = this.getSubmissions()
    this.loading = false
  },
  methods: {
    ...mapActions('BriefsModule', ['fetchBriefs']),
    getSubmissions () {
      const submissions = []
      this.briefs.forEach(brief => {
        if (brief.submissions) {
          brief.submissions.forEach(submission => {
            submissions.push({ submission, brief })
          })
        }
      })
      submissions.sort((a, b) => b.submission.submittedAt - a.submission.submittedAt)
      if (submissions.length > 10) {
        const topTenSubmissions = submissions.slice(0, 10)
        return topTenSubmissions
      } else {
        return submissions
      }
    },
    getFormat (format) {
      if (format === 'image/jpg') {
        return '.jpg'
      } else if (format === 'image/jpeg') {
        return '.jpeg'
      } else if (format === 'image/png') {
        return '.png'
      } else if (format === 'image/gif') {
        return '.gif'
      } else if (format === 'image/bmp') {
        return '.bmp'
      } else if (format === 'image/webp') {
        return '.webp'
      } else if (format === 'image/svg+xml') {
        return '.svg'
      } else if (format === 'video/mp4') {
        return '.mp4'
      } else if (format === 'video/webm') {
        return '.webm'
      } else if (format === 'video/ogg') {
        return '.ogg'
      } else {
        return ''
      }
    },
    formatDate (date) {
      date = new Date(date)
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      const year = date.getFullYear()

      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }

      return [month, day, year].join('/')
    }
  }
}
</script>
