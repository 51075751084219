import { render, staticRenderFns } from "./DiscoveryCard.vue?vue&type=template&id=010524e9&scoped=true"
import script from "./DiscoveryCard.vue?vue&type=script&lang=js"
export * from "./DiscoveryCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010524e9",
  null
  
)

export default component.exports