<template>
  <BaseModal
    no-padding
    class="rounded-3xl width-2/3"
    @close="$emit('close')"
  >
    <template #toolbar>
      <div class="text-black w-full items-center font-medium justify-center ">
        <div class="flex items-center -mt-1">
          <img
            src="../../../assets/images/barryIcon.png"
            class="h-16 mt-1.5"
          >
          <div class="ml-3 -mt-2.5">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Building Ads with Barry
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal w-52 overflow-visible whitespace-nowrap"
            >
              Free Course Preview
            </BaseText>
          </div>
        </div>
      </div>
    </template>
    <div
      class="p-0 pb-3 overflow-y-scroll scrollbar-hide h-full relative"
      style="max-height: 540px;"
    >
      <!-- Divider -->
      <div
        class=" border-t fixed"
        style="width: 600px;"
      />
      <CourseDrawer
        :course="{ name: 'Optimizing for Ads People Love', videoCount: 5, number: 'Preview' }"
        show-video
        default-open
      />
      <CourseDrawer
        :course="{ name: 'No BS Audience Research and Creative Strategy (AI-Enhanced)', videoCount: 11, number: '1' }"
        locked
      />
      <CourseDrawer
        :course="{ name: 'Creating Ads People Don’t Hate', videoCount: 6, number: '2' } "
        locked
      />
      <CourseDrawer
        :course="{ name: 'How the System Works & Getting it to Work for You', videoCount: 6, number: '3' } "
        locked
      />
      <CourseDrawer
        :course="{ name: 'How to Optimize Performance', videoCount: 6, number: '4' } "
        locked
      />
      <CourseDrawer
        v-for="(course, index) in courses"
        :key="index"
        :course="course"
        locked
      />
    </div>
    <div
      class=" flex items-center px-5 py-5 border-t border-border-normal rounded-b-3xl"
    >
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted ml-2.5"
      >
        Course & Live Training Starting Feb 3rd
      </BaseText>
      <a
        href="https://www.buildingadswithbarry.com/?foreplay200"
        target="_blank"
        class="w-max ml-auto"
      >
        <BaseButton
          class="w-max ml-auto"
          outlined
        >
          <div
            class="flex items-center justify-center gap-2 -mx-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M17.1592 9.62461L16.3647 5.29873C16.2612 4.73548 15.8205 4.29474 15.2572 4.19129L10.9313 3.39674C10.4853 3.31481 10.0275 3.45737 9.70684 3.77803L4.12323 9.36164C3.58457 9.9003 3.58457 10.7736 4.12323 11.3123L9.24366 16.4327C9.78231 16.9714 10.6556 16.9714 11.1943 16.4327L16.7779 10.8491C17.0986 10.5284 17.2411 10.0706 17.1592 9.62461Z"
                stroke="#5E6678"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5833 7.43815C12.2381 7.43815 11.9583 7.71797 11.9583 8.06315C11.9583 8.40833 12.2381 8.68815 12.5833 8.68815C12.9285 8.68815 13.2083 8.40833 13.2083 8.06315C13.2083 7.71797 12.9285 7.43815 12.5833 7.43815Z"
                fill="#5E6678"
                stroke="#5E6678"
                stroke-width="0.416667"
              />
            </svg>
            Save $200 With Code “foreplay200”
          </div>
        </BaseButton>
      </a>
    </div>
  </BaseModal>
</template>

<script>
import CourseDrawer from '../CourseDrawer.vue'
export default {
  name: 'FolderModal',
  components: {
    CourseDrawer
  },
  data () {
    return {
      openCards: [],
      courses: [
        { name: 'Creative Testing & Data Review', videoCount: 3, number: 4 }
      ]
    }
  }
}
</script>

<style scoped></style>
