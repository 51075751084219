<template>
  <div
    class="flex flex-col items-center justify-center px-5"
  >
    <BaseCardTransition>
      <BaseCard
        class="h-max mt-3 rounded-xl bg-white w-full"
        border
      >
        <div
          class="flex justify-between"
          :class="openDrawer.includes(course.name) && !locked ? 'border-b border-neutral-50 border-opacity-80' : '' "
        >
          <div
            class="py-3 px-3.5 rounded-full flex"
          >
            <div
              class="rounded-md py-0.5 px-2 flex items-center justify-center"
              style="box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
            >
              <BaseText
                type="label"
                size="sm"
                class="whitespace-nowrap"
              >
                <span class="font-normal">
                  {{ course.number }}
                </span>
              </BaseText>
            </div>
            <div
              class="w-full ml-3 flex items-center mr-auto"
            >
              <BaseText
                type="label"
                size="sm"
                class="whitespace-nowrap truncate flex-1 max-w-xs"
                :class="locked && !showVideo ? 'text-text-subdued' : 'text-text-muted'"
              >
                <span class="font-normal">
                  {{ course.name }}
                </span>
              </BaseText>
              <!-- <BaseBadge
                class="ml-2 text-label-xs"
                blue
              >
                {{ course.videoCount }} Videos
              </BaseBadge> -->
              <BaseBadge
                class="ml-2 text-label-xs"
                :class="locked ? 'text-text-disabled' : ''"
                :green="!locked"
                :gray="locked"
              >
                {{ locked ? 'Locked' : 'FREE' }}
              </BaseBadge>
            </div>
          </div>
          <img
            v-if="locked"
            src="../../assets/icons/locked-dark.svg"
            alt=""
            class="w-5 mr-5 transition-transform duration-300 cursor-pointer "
            :class="openDrawer.includes(course.name) && !locked ? 'rotate-180 transform' : ''"
            @click="openDrawer.includes(course.name) ? openDrawer.splice(openDrawer.indexOf(course.name), 1) : openDrawer.push(course.name)"
          >
          <img
            v-else
            src="../../assets/icons/chevron-down-small.svg"
            alt=""
            class="w-5 mr-5 transition-transform duration-300 cursor-pointer "
            :class="openDrawer.includes(course.name) && !locked ? 'rotate-180 transform' : ''"
            @click="openDrawer.includes(course.name) ? openDrawer.splice(openDrawer.indexOf(course.name), 1) : openDrawer.push(course.name)"
          >
        </div>
        <div
          v-if="openDrawer.includes(course.name) && showVideo"
          class="px-3.5 py-3.5"
        >
          <div
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <iframe
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/_nr1WUj0e_M?si=uvJaslFTZ0Ot6c-V"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                class="rounded-xl"
                allowfullscreen
              />
            </div>
          </div>
          <!-- <div class="flex gap-2.5 mt-2.5">
            <BaseText
              type="label"
              size="sm"
            >
              <span class="font-normal">
                2.1 Sales Phycology Basics
              </span>
            </BaseText>
            <BaseBadge
              class="text-label-xs"
              green
            >
              FREE Preview
            </BaseBadge>
          </div> -->
        </div>
      </BaseCard>
    </BaseCardTransition>
  </div>
</template>
<script>
export default {
  name: 'CourseDrawer',
  props: {
    course: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    showVideo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      openDrawer: []
    }
  },
  mounted () {
    if (this.defaultOpen) {
      this.openDrawer.push(this.course.name)
    }
  }
}
</script>
<style></style>
